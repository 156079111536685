export default {
  async insert (Vue, formData, idvigilante) {
    const resp = await Vue.$api.call(
      'parteNovedad.insert',
      {
        values: {
          idpuesto_servicio: formData.idpuestoServicio,
          identificador: null,
          idvigilante,
          fparte: formData.fparte,
          observaciones: formData.observaciones,
        },
      }
    )
    return resp.data.result.dataset[0]
  },
}
